import React from 'react';
import { Row, Col } from 'react-bootstrap';


const Contacto = () => {
  return (
    <div className="bg-dark2 d-none d-md-block " id='contact'> 
      <Row className="justify-content-center py-5 g-0">
        <Col md={10} className="text-center " >
          <h1 style={{
              fontFamily: 'Libre Franklin,'
              }}>
                <span className='t-700 py-5'>TU CARRO EN LAS MEJORES MANOS:</span> ¡SELECCIONA ENTRE LOS TRANSPORTISTAS MAS CONFIABLES CON NUESTRA AYUDA!
              </h1>
          <div className="my-4 justify-content-center text-center ">
            {/* Botón con color definido en CSS */}
            <a className="custom-color-btn btn bg-700 border-0 fw-bold font-f px-5 me-4 py-2 text-white" style={{textDecoration: 'none'}} href='#form'>COTIZAR AHORA</a>
            {/* Botón transparente con bordes redondeados */}
            <a href='tel:+18704556742' className="btn btn-outline-primary  py-2 custom-color-btn font-f px-5" style={{textDecoration: 'none'}}>CONTACTAR AL <span className='font-f fw-bold t-200'>(1) 870-455-6742</span></a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Contacto;
