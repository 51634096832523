import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Infort = () => {
  const imagesInfo = [
    { src: './images/mensaje.png'},
    { src: './images/star.png' },
    { src: './images/cartera.png' },
    { src: './images/curita.png' },
    { src: './images/pulgar.png' },
    { src: './images/audifonos.png' },
  ];

  return (
    <div className="text-center bg-infort mx-auto g-0">
      <h1 className='text-font pt-5'>LLEVAMOS TU VEHICULO</h1>
      <p className='text-font mt-2'>Conocemos a la perfección el mercado de Transportistas y solo te recomendamos a las empresas líderes en el mercado.</p>
      <Row className="justify-content-center mb-3 mt-5 mx-0 g-0">
        {imagesInfo.slice(0, 3).map((image, index) => (
          <Col key={index} xs={12} md={4} className="mb-3">
            <img src={image.src} alt={`Imagen ${index + 1}`} className="image-infort"/>
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center mx-auto g-0">
        {imagesInfo.slice(3).map((image, index) => (
          <Col key={index} xs={12} md={4} className="mb-3">
            <img src={image.src} alt={`Imagen ${index + 4}`} className="image-infort"/>
           </Col>
        ))}
      </Row>
    </div>
  );
};

export default Infort;