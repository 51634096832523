import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";

const Step = () => {
  const stepsData = [
    {
      src: "/images/step1.svg",
      title: "Cotiza",
      description:
        "Diligencia todos los campos y envía tu cotización desde la parte superior de esta página o llámanos.",
    },
    {
      src: "/images/step2.svg",
      title: "Compara",
      description:
        "Recibe las cotizaciones de los transportistas compitiendo por clientes.",
    },
    {
      src: "/images/step3.svg",
      title: "Decide",
      description:
        "Selecciona la cotización más conveniente por precio o servicio, y contrata el servicio directamente con la empresa.",
    },
  ];

  return (
    <div className="degraded-background2" id="us">
      <Row className="justify-content-center g-0 p-xs-5">
        <Col md={6} className="text-font text-center-right py-5 px-6">
          <p className="mt-5 t-200 mb-0">Transporte terrestre</p>
          <h1 className="w-100 mb-3 mt-0 pe-5" style={{ fontSize: "2.5em" }}>
            ENCUENTRA AL TRANSPORTISTA <span className="t-700">IDEAL</span>
          </h1>
          <p>Comparativa de Servicios para tu Carro</p>
          <Button className="custom-color-btn bg-700 border-0 font-f mb-3 d-block d-md-none w-100 py-3">
            COTIZAR AHORA
          </Button>
          <a href="tel:+18704556742"
            className="btn btn-btn-outline-primary text-white border-white font-f mb-3 w-100 py-3"
          >
            Llámanos al (1) 870-455-6742
          </a>
        </Col>
        <Col md={6} className="pt-3 px-6">
          {stepsData.map((step, index) => (
            <Card
              key={index}
              className="step-container mb-3 bg-cardreview-step text-font"
            >
              <Row className="g-0 align-items-center">
                <Col
                  xs={12}
                  md={2}
                  className="mb-2 mb-md-0 mt-3 d-flex justify-items-start "
                >
                  <Card.Img
                    className="w-img ms-2"
                    src={step.src}
                    alt={`Step ${index + 1}`}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Card.Body className="text-font">
                    <Card.Title as="h4" style={{ color: "white" }}>
                      {step.title}
                    </Card.Title>
                    <Card.Text style={{ color: "white" }}>
                      {step.description}
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Step;
