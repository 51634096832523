import React from 'react';
import {Row, Col, Button } from 'react-bootstrap';

const Servicios = () => {
  return (
    <div >
   {/* Contenido para escritorio - se oculta en tamaños menores a md */}
   <Row className="d-none d-md-flex content-center align-items-center text-center px-5 mt-5 g-0">
        <Col md={12} className='px-5'>
          <h1 className='text-font px-5'>AL CONTRATAR NUESTROS SERVICIOS, ACEPTAN NUESTROS TÉRMINOS & CONDICIONES</h1>
          <div className="d-flex justify-content-center mb-5 mt-5">
            <Button variant="primary" className="px-5 mx-5 py-3 bg-700 border-0 font-f" >DESCARGAR Y LEER</Button>
            <a href='tel:+18704556742' className="btn btn-outline-primary px-5 mx-2" ><span className='font-f text-font'>ASESORATE AL <span className='fw-bold'>(1) 870-455-6742</span></span></a>
          </div>
        </Col>
      </Row>
      
      {/* Contenido para móvil - se muestra solo en tamaños menores a md */}

      <Row className="d-md-none justify-content-center align-items-center text-center g-0 bg-services">
        <Col xs={12} style={{ padding: 0 }} >
          {/* Asegúrate de que el 'div' de la imagen tenga una altura o el 'img' sea del tamaño correcto */}
          <div style={{ padding: 0 }}>
  <img src="/images/services.png" alt="Nuestros Servicios" style={{ width: '100%', height: 'auto' }} />
            </div>
        </Col>
        <Col xs={12}>
          <h1 className='text-font mt-3 px-4'>AL CONTRATAR NUESTROS SERVICIOS, ACEPTAN NUESTROS TÉRMINOS & CONDICIONES</h1>
          <div className="d-flex flex-column justify-content-center">
          <Button variant="primary" className="mx-5 bg-700 border-0 font-f my-4" >DESCARGAR Y LEER</Button>
          <a href='tel:+18704556742' className="btn btn-outline-primary mx-5 mb-5"><span className='font-f text-font'>ASESORATE AL <span className='fw-bold'>(1) 870-455-6742</span></span></a>
          </div>
        </Col>
      </Row>
      </div>
  );
};

export default Servicios;
