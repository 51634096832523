import propTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/es';
import { Image } from 'react-bootstrap';

//TODO: revisar clases de estilos
//TODO: revisar fecha de publicación
//TODO: revisar tiempo de lectura
//TODO: revisar logo
const PostHeadBar = ({ from, date, readingTime }) => (
    <div className="d-flex mb-3 m-lg-0 align-items-center " id="postInfoHead">
        <Image src="/images/logo-small.png" width="50" alt="Logo" />
        <div className='d-none d-lg-flex gap-1'>
            <p className="post-p-small m-0">{from}</p>
            <p className="post-p-small m-0">{`- ${formatDate(date)} - `}</p>
            <p className="post-p-small m-0">{readingTime}</p>
        </div>
        <div className='d-flex d-lg-none flex-column'>
            <p className="post-p-small m-0">{from}</p>
            <p className="post-p-small m-0">{`${formatDate(date)} - ${readingTime}`}</p>
        </div>
    </div>
);
export default PostHeadBar;

PostHeadBar.propTypes = {
    from: propTypes.string.isRequired,
    date: propTypes.string.isRequired,
    readingTime: propTypes.string.isRequired
}

/**
 * Given a date in string format, returns it in the format DD MMMM YYYY.
 * First sets the locale to 'es' to get the month name in Spanish.
 * @param {string} date - The date to format.
 * @returns {string} The formatted date.
 */
const formatDate = (date) => {
    moment.locale('es');
    let dateToFormat = moment(date);
    let formattedDate = dateToFormat.format('DD MMMM YYYY');

    return formattedDate.replace(
        formattedDate.charAt(3),
        formattedDate.charAt(3).toUpperCase()
    );
};