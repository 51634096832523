import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import '../../Goal.css'

const Goal = () => {
  const goalsData = [
    { end: 7, title: ' Años de experiencia' },
    { end: 5622, title: ' Clientes satisfechos' },
    { end: 312, title: ' Transportistas licenciados' },
    { end: 12000, title: 'Asesorías', prefix: '+' },
  ];

  return (
    <Row className="justify-content-center text-center goal-row g-0">
      {goalsData.map((goal, index) => (
        <Col key={index} xs={6} md={3} className="goal-col mb-4">
          <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
            {({ isVisible }) => (
              <div className="goal-number text-font">
                <CountUp end={isVisible ? goal.end : 0} prefix={goal.prefix || ''} duration={1} />
              </div>
            )}
          </VisibilitySensor>
          <div className="goal-title text-font">{goal.title}</div>
        </Col>
      ))}
    </Row>
  );
};

export default Goal;