import React from 'react';
import { Container } from 'react-bootstrap';


//TODO: Revisar Container
const BlockedMobile = () => {
  return (
    <Container className="d-block d-md-none text-center bg-faq mt-4 py-3">
        <div className='g-0'>
        <p className='bg-faq'>©2024 GARLAS LLC</p>
        </div>
    </Container>
  );
};

export default BlockedMobile;