import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';



const Review = () => {

    const reviews = [
        {
          name: "ALFONSO SANTACRUZ",
          location: "Envío de USA a Guatemala ",
          review:"No tuve que gastar tiempo buscando, me contactaron enseguida, excelente el servicio estuvieron en contacto conmigo hasta recibir el vehículo."
        },
        {
          name: "GRACIELA DE MANRIQUE",
          location: "Envió de Puerto Rico a Detroit, US.",
          review: "Escogí la empresa que más me gustó y cumplieron de maravilla."
        },
        {
          name: "MARCELA ORTEGA",
          location: "Envío dentro de USA",
          review: "Súper confiables y seguros,  el vehículo llegó el día que me dijeron y me permitieron llevar artículos dentro de la cajuela."
        },
      ];


  return (
    <Container fluid className="p-3 degraded-background container-fluid " id="testimonios">
      {/* Asumiendo que deseas un título encima de tus tarjetas */}
      <Row className=" d-flex d-md justify-content-center py-3 text-font font-f">
        <Col>
          <h2 className="text-center fw-bold">LOS CLIENTES DICEN</h2>
        </Col>
      </Row>

      <Row className="justify-content-center py-3 mx-auto">
      {reviews.map((review, index) => (
          <Col md={4} className="mb-5 d-flex justify-content-center" key={index}>
            <Card className="h-100 bg-cardreview custom-card custom-card-body">
              <Card.Body className="custom-card-body d-flex ">
              <div className="my-2 ">
                    <img src="./images/rating.svg" className=' d-flex' alt="Rating" style={{ width: 'auto', height: 'auto', content:'center'}} />
            </div>
                <Card.Title className='fw-bold font-f' style={{ color: '#FFCBD6' }}>{review.name}</Card.Title>
                <Card.Subtitle className="mb-2 fw-bold" style={{ color: 'white' }}>{review.location}</Card.Subtitle>
                <Card.Text className='text-font text-center'>
                {review.review}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Review;