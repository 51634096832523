import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Post from './components/blog/Post';
import Footer from './components/common/Footer';
import FooterMobile from './components/common/FooterMobile';
import NavbarComp from './components/common/NavbarComp';
import Blog from './pages/Blog';
import Contacto from './pages/Contact';
import CotizacionMobile from './pages/CotizarMobile';
import Gracias from './pages/Gracias';
import HomeRoutes from './pages/Home';
import { BlogProvider } from './context/useBlogContext';

function App() {
  return (
    <Router>
      <div className="app bg-navbar">
        <NavbarComp />
        <Routes>
          <Route path="/" element={<HomeRoutes />} />
          <Route path="/cotizacion" element={<CotizacionMobile />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/gracias" element={<Gracias />} />
          <Route
            path="/blog/*"
            element={
              <BlogProvider>
                <Routes>
                  <Route path="" element={<Blog />} />
                  <Route path="post/:id" element={<Post />} />
                </Routes>
              </BlogProvider>
            }
          />
        </Routes>
        <Footer />
        <FooterMobile />
      </div>
    </Router>
  );
}
export default App;
