import React from 'react';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCustomNavigate } from '../../utils/customNavigation';
import { useNavigate } from 'react-router-dom';

function NavbarComp() {
  const customNavigate = useCustomNavigate();
  const navigate = useNavigate();
  const handleNavLinkClick = (path, hash) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay to ensure navigation has completed
  };

  return (
    <>
      <div className='bg-700'>
        <a href='tel:+18704556742' target="_blank" rel="noopener noreferrer" className='link-info text-center text-white py-2 d-none d-md-block text-no-dec'>¿Tienes preguntas? Llámanos (1) 870-455-6742</a>
      </div>
      <Navbar className="bg-navbar2" variant="dark" expand="md" sticky="top">
        <Container>
          <Navbar.Brand >
            <img
              src="../../images/logotcp.png" // Asegúrate de que la ruta sea correcta.
              width="147"
              height="32"
              className="d-inline-block align-top"
              alt="Logo"
              onClick={() => customNavigate('#form')}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto "> {/* Alinea los elementos del Nav a la derecha */}
              <Nav.Link onClick={() => customNavigate('#us')} className='text-font me-5'>Servicios</Nav.Link>
              <Nav.Link onClick={() => customNavigate('#faq')} className='text-font me-5'>FAQ</Nav.Link>
              <Nav.Link onClick={() => customNavigate('#contact')} className='text-font me-5'>Contacto</Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/blog', 'top')} className='text-font me-5'>Blog</Nav.Link>

              <Button onClick={() => customNavigate('#form')} variant="outline-light" style={{ backgroundColor: '#E21454', borderColor: '#E21454' }}>
                Cotizar ahora
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComp;